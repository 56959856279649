import React from 'react';
import { fromUnixTimestamp } from '@core/utils/dates';
import { FontAwesome } from '@shared';
import { EventInfoModal } from './EventInfoModal';
import { Media } from 'reactstrap';
import { shortName } from '@core/utils/names';
import { EventAsset } from './EventAsset';
import { EventItem } from './EventItem';
import { EventKit } from './EventKit';
import { EventBuilding } from './EventBuilding';
import { EventMovement } from './EventMovement';
import { EventMRO } from './EventMRO';
import { EventTag } from './EventTag';
import { EventArea } from './EventArea';
import { EventTask } from './EventTask';
import { EventSubtask } from './EventSubtask';
import { EventDevice, EventDeviceKey } from './EventDevice';
import { EventMembership } from './EventMembership';
import { EventReadout } from './EventReadout';
import { EventGeofence } from './EventGeofence';
import { EventAttachment } from './EventAttachment';
import { EventStatement } from './EventStatement';
import { EventStockout } from './EventStockout';

export const EventRow = ({ event }) => {
  return (
    <tr
      className={`event-border event-${
        event.metadata !== {} && event.metadata.pattern ? event.metadata.pattern.split(':')[1] : null
      }`}
    >
      <td>
        <Media className={`ml-2 align-items-center`}>
          <Media left className="mr-1 h4 text-info">
            <FontAwesome name="caret-right" />
          </Media>
          <Media body className="d-block small">
            <div>
              <strong>
                {event.metadata !== {} && event.metadata.data && event.metadata.data.description
                  ? event.metadata.data.description
                  : event.metadata.pattern}
              </strong>
            </div>
            {event.metadata !== {} && event.metadata.data && event.metadata.data.date && (
              <div>
                Fecha:{' '}
                <span className="text-muted" style={{ minWidth: '150px' }}>
                  {fromUnixTimestamp(event.metadata.data.date)}
                </span>
              </div>
            )}
          </Media>
        </Media>
      </td>
      <td>
        {event.metadata !== {} && event.metadata.data && event.metadata.data.user && (
          <div className="d-block small">
            <FontAwesome name="user" /> {shortName(event.metadata.data.user)}
          </div>
        )}
        {event.metadata !== {} && event.metadata.data && event.metadata.data.building && (
          <div className="d-block small">
            <FontAwesome name="industry" tooltip="Instalación" />
            {event.metadata.data.building.name}
          </div>
        )}
      </td>
      <td style={{ maxWidth: '450px', overflow: 'auto' }}>
        {event.metadata !== {} &&
        event.metadata.data &&
        event.metadata.data.message &&
        event.metadata.pattern.includes('asset:') ? (
          event.metadata.data.message && <EventAsset asset={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('item:') ? (
          event.metadata.data.message && <EventItem item={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('building:') ? (
          event.metadata.data.message && <EventBuilding building={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('kit:') ? (
          event.metadata.data.message && <EventKit kit={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('movement:stockout') ? (
          event.metadata.data.message && <EventStockout stockout={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('movement:') ||
          event.metadata.pattern.includes('repair_provider:') ||
          event.metadata.pattern.includes('movement_mro:') ||
          event.metadata.pattern.includes('repair_provider_mro:') ||
          event.metadata.pattern.includes('asset_repair:') ||
          event.metadata.pattern.includes('asset_evaluation:') ||
          event.metadata.pattern.includes('asset_maintenance:') ||
          event.metadata.pattern.includes('asset_preservation:') ||
          event.metadata.pattern.includes('asset_certification:') ||
          event.metadata.pattern.includes('asset_assurance:') ||
          event.metadata.pattern.includes('asset_discard:') ? (
          event.metadata.data.message && <EventMovement movement={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('repair_mro:') ||
          event.metadata.pattern.includes('evaluation_mro:') ||
          event.metadata.pattern.includes('maintenance_mro:') ||
          event.metadata.pattern.includes('preservation_mro:') ||
          event.metadata.pattern.includes('certification_mro:') ||
          event.metadata.pattern.includes('assurance_mro:') ||
          event.metadata.pattern.includes('discard_mro:') ? (
          event.metadata.data.message && <EventMRO mro={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('assembly_normal_tag:') ||
          event.metadata.pattern.includes('reassembly_normal_tag:') ? (
          event.metadata.data.message && <EventAsset asset={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('tag:') ||
          event.metadata.pattern.includes('assembly_batch:') ||
          event.metadata.pattern.includes('assembly_hard_tag:') ||
          event.metadata.pattern.includes('reassembly_hard_tag:') ? (
          event.metadata.data.message && <EventTag tag={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('area:') ? (
          event.metadata.data.message && <EventArea area={event.metadata.data.message} />
        ) : event.metadata.pattern.match(/^task:/) ? (
          event.metadata.data.message && <EventTask task={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('sub_task:') ? (
          event.metadata.data.message && <EventSubtask subtask={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('device:') ? (
          event.metadata.data.message && <EventDevice device={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('device_key:') ? (
          event.metadata.data.message && <EventDeviceKey deviceKey={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('membership:') ? (
          event.metadata.data.message && <EventMembership membership={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('readout:') ? (
          event.metadata.data.message && <EventReadout readout={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('geo_in:') ||
          event.metadata.pattern.includes('geo_out:') ||
          event.metadata.pattern.includes('lowbattery:') ? (
          event.metadata.data.message && <EventGeofence geofence={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('attachment:') ? (
          event.metadata.data.message && <EventAttachment attachment={event.metadata.data.message} />
        ) : event.metadata.pattern.includes('statement:') ? (
          event.metadata.data.message && <EventStatement statement={event.metadata.data.message} />
        ) : (
          <div>Sin información relevante</div>
        )}
      </td>
      <td className="align-middle small text-center">
        {event.metadata !== {} && event.metadata.data && event.metadata.data.message && (
          <EventInfoModal
            pattern={event.metadata.pattern}
            description={event.metadata.data.description}
            user={event.metadata.data.user}
            eventInfo={event.metadata.data.message}
          />
        )}
      </td>
    </tr>
  );
};
